export enum Type {
  Poster,
  Hanger,
}

export interface Product {
  variants: Variant[];
  type: Type;
}

export interface Variant {
  displayName: string;
  description: string;
  size: Size;
  style: string;
  price: Price;
  sku: string;
  metadata?: any;
  image: string;
}

export interface Price {
  amount: number;
  currency: string;
}
export interface Size {
  width: string;
  height: string;
  unit: string;
}

export interface ProductsDefinition {
  posters: Product;
  hangers: Product;
}

export const printSizes = {
  "30x40": {
    main: "68px", // default x 4
  },
  "50x70": {
    main: "112px", // default x 4
  },
  "70x100": {
    main: "156px", // default x 4
  },
};

export const sizes = {
  "30x40": {
    // 850x1134 / 3 == 283,33x378 == 0.74944908289
    default: {
      fontSize: {
        main: "17px",
        title: "1em",
        subTitle: "0.5em",
      },
      width: 283.33,
      height: 378,
    },
    tiny: {
      fontSize: {
        main: "18px",
        title: "1em",
        subTitle: "0.5em",
      },
      width: 300,
      height: 400.3,
    },
  },
  "50x70": {
    // 1416x1984 / 3 == 472x661,33 == 0.71371327476
    default: {
      fontSize: {
        main: "28px",
        title: "1.1em",
        subTitle: "0.6em",
      },
      width: 472,
      height: 661.33,
    },
    tiny: {
      fontSize: {
        main: "18px",
        title: "1.1em",
        subTitle: "0.6em",
      },
      width: 300,
      height: 420,
    },
  },
  "70x100": {
    // 1984x2833 / 3 == 661,33x944,33 == 0.70031768443
    default: {
      fontSize: {
        main: "39px",
        title: "1.1em",
        subTitle: "0.6em",
      },
      width: 661.33,
      height: 944.33,
    },
    tiny: {
      fontSize: {
        main: "18px",
        title: "1.1em",
        subTitle: "0.6em",
      },
      width: 300,
      height: 428.38,
    },
  },
};

export const products: ProductsDefinition = {
  posters: {
    type: Type.Poster,
    variants: [
      {
        displayName: "Classic",
        size: {
          height: "40",
          width: "30",
          unit: "cm",
        },
        description: "Originalet.",
        style: "classic",
        price: {
          amount: 370,
          currency: "sek",
        },
        sku: "cl30x40",
        image: "/images/thumbnails/classic-thumb.jpeg",
      },
      {
        displayName: "Classic",
        size: {
          height: "70",
          width: "50",
          unit: "cm",
        },
        description: "Originalet.",
        style: "classic",
        price: {
          amount: 470,
          currency: "sek",
        },
        sku: "cl50x70",
        image: "/images/thumbnails/classic-thumb.jpeg",
      },
      {
        displayName: "Classic",
        size: {
          height: "100",
          width: "70",
          unit: "cm",
        },
        description: "Originalet.",
        style: "classic",
        price: {
          amount: 570,
          currency: "sek",
        },
        sku: "cl70x100",
        image: "/images/thumbnails/classic-thumb.jpeg",
      },
      {
        displayName: "Noir",
        size: {
          height: "40",
          width: "30",
          unit: "cm",
        },
        description: "Bejaka din mörka sida.",
        style: "noir",
        price: {
          amount: 370,
          currency: "sek",
        },
        sku: "no30x40",
        image: "/images/thumbnails/classic-thumb.jpeg",
      },
      {
        displayName: "Noir",
        size: {
          height: "70",
          width: "50",
          unit: "cm",
        },
        description: "Bejaka din mörka sida.",
        style: "noir",
        price: {
          amount: 470,
          currency: "sek",
        },
        sku: "no50x70",
        image: "/images/thumbnails/classic-thumb.jpeg",
      },
      {
        displayName: "Noir",
        size: {
          height: "100",
          width: "70",
          unit: "cm",
        },
        description: "Bejaka din mörka sida.",
        style: "noir",
        price: {
          amount: 570,
          currency: "sek",
        },
        sku: "no70x100",
        image: "/images/thumbnails/classic-thumb.jpeg",
      },
    ],
  },

  hangers: {
    type: Type.Hanger,
    variants: [
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "31",
          unit: "cm",
        },
        description:
          "Svart och stilren. Ramar in dina affärer på ett smakfullt sätt.",
        style: "svart",
        price: {
          amount: 110,
          currency: "sek",
        },
        sku: "ELAPH31BW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51svart.png",
      },
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "31",
          unit: "cm",
        },
        description: "Den mörka eken i denna hängare ger en tidlös känsla.",
        style: "ek",
        price: {
          amount: 110,
          currency: "sek",
        },
        sku: "ELAPH31OW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51-ek.png",
      },
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "31",
          unit: "cm",
        },
        description:
          "Denna vita hängare ger en bra kontrast till vår mörka serie Noir.",
        style: "vit",
        price: {
          amount: 110,
          currency: "sek",
        },
        sku: "ELAPH31WW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51vit.png",
      },
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "51",
          unit: "cm",
        },
        description:
          "Svart och stilren. Ramar in dina affärer på ett smakfullt sätt.",
        style: "svart",
        price: {
          amount: 150,
          currency: "sek",
        },
        sku: "ELAPH51BW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51svart.png",
      },
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "51",
          unit: "cm",
        },
        description: "Den mörka eken i denna hängare ger en tidlös känsla.",
        style: "ek",
        price: {
          amount: 150,
          currency: "sek",
        },
        sku: "ELAPH51OW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51-ek.png",
      },
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "51",
          unit: "cm",
        },
        style: "vit",
        price: {
          amount: 150,
          currency: "sek",
        },
        description:
          "Denna vita hängare ger en bra kontrast till vår mörka serie Noir.",
        sku: "ELAPH51WW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51vit.png",
      },
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "71",
          unit: "cm",
        },
        description:
          "Svart och stilren. Ramar in dina affärer på ett smakfullt sätt.",
        style: "svart",
        price: {
          amount: 190,
          currency: "sek",
        },
        sku: "ELAPH71BW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51svart.png",
      },
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "71",
          unit: "cm",
        },
        description: "Den mörka eken i denna hängare ger en tidlös känsla.",
        style: "ek",
        price: {
          amount: 190,
          currency: "sek",
        },
        sku: "ELAPH71OW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51-ek.png",
      },
      {
        displayName: "Hängare",
        size: {
          height: "",
          width: "71",
          unit: "cm",
        },
        description:
          "Denna vita hängare ger en bra kontrast till vår mörka serie Noir.",
        style: "vit",
        price: {
          amount: 190,
          currency: "sek",
        },
        sku: "ELAPH71WW",
        image:
          "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51vit.png",
      },
    ],
  },
};

export const classicConfig = {
  chartType: "line",
  resolution: "weekly",
  dateColor: "#bfbfbf",
  line: {
    lineColor: "#207CD1",
    gradientData: [
      { offset: "0%", color: "#207CD1", opacity: "0.25" },
      { offset: "20%", color: "#207CD1", opacity: "0.25" },
      { offset: "100%", color: "#207CD1", opacity: "0.0" },
    ],
  },
  ticks: {
    fontSize: "14px",
    fontWeight: "400",
    fillColor: "black",
    opacity: "0.3",
    font: "Circular",
  },
};
export const noirConfig = {
  chartType: "line",
  resolution: "weekly",
  line: {
    lineColor: "#FFFFFF",
    strokeWidth: "",
    gradientData: [
      { offset: "0%", color: "#FFFFFF", opacity: "0.25" },
      { offset: "30%", color: "#FFFFFF", opacity: "0.20" },
      { offset: "100%", color: "#FFFFFF", opacity: "0.0" },
    ],
  },
  ticks: {
    fontSize: "14px",
    fontWeight: "400",
    fillColor: "white",
    opacity: "0.9",
    font: "Circular",
  },
};

export const topPosterRow = {
  title: "Våra produkter",
  description: "En stilrena Classic eller mörka Noir. Valet är ditt!",
  items: [
    {
      title: "Investor B",
      description: "Investor B i Stockiful Classic",
      image: "/images/frontpage/investor.jpg",
      slug: "354",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
    {
      title: "Azelio",
      description: "Azelio i Stockiful Noir",
      image: "/images/frontpage/azelio.jpg",
      slug: "4082110",
      type: "poster",
      theme: "noir",
      size: "50x70cm",
    },
    {
      title: "Evolution",
      description: "Evolution i Stockiful Classic",
      image: "/images/frontpage/evolution.jpg",
      slug: "1034561",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
  ],
};

export const row404 = {
  title: "Här var det tomt 🤔",
  description: "Testa några av dessa istället",
  items: [
    {
      title: "Investor B",
      description: "Investor B i Stockiful Classic",
      image: "/images/frontpage/investor.jpg",
      slug: "354",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
    {
      title: "Azelio",
      description: "Azelio i Stockiful Noir",
      image: "/images/frontpage/azelio.jpg",
      slug: "4082110",
      type: "poster",
      theme: "noir",
      size: "50x70cm",
    },
    {
      title: "Evolution",
      description: "Evolution i Stockiful Classic",
      image: "/images/frontpage/evolution.jpg",
      slug: "1034561",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
  ],
};

export const hangerRow = {
  title: "Posterhängare",
  description: "Köp till en hängare, så att du kan visa upp din aktieposter.",
  items: [
    {
      title: "Hängare Vit",
      description: "Finns i flera storlekar",
      image:
        "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51vit.png",
      slug: "ELAPH51WW",
      type: "hanger",
      theme: "white",
      size: "51cm",
    },
    {
      title: "Hängare Svart",
      description: "Finns i flera storlekar",
      image:
        "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51svart.png",
      slug: "ELAPH51BW",
      type: "hanger",
      theme: "black",
      size: "51cm",
    },
    {
      title: "Hängare ek",
      description: "Finns i flera storlekar",
      image:
        "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51-ek.png",
      slug: "ELAPH51OW",
      type: "hanger",
      theme: "oak",
      size: "51cm",
    },
  ],
};

export const cartHangerRow = {
  title: "Posterhängare",
  description: "Köp till en hängare, så att du kan visa upp din poster.",
  items: [
    {
      title: "Hängare Vit",
      description: "Finns i flera storlekar",
      image:
        "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51vit.png",
      slug: "ELAPH51WW",
      type: "hanger",
      theme: "white",
      size: "51cm",
    },
    {
      title: "Hängare Svart",
      description: "Finns i flera storlekar",
      image:
        "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51svart.png",
      slug: "ELAPH51BW",
      type: "hanger",
      theme: "black",
      size: "51cm",
    },
    {
      title: "Hängare ek",
      description: "Finns i flera storlekar",
      image:
        "https://stockiful-frontpage.s3.eu-north-1.amazonaws.com/hanger-51-ek.png",
      slug: "ELAPH51OW",
      type: "hanger",
      theme: "oak",
      size: "51cm",
    },
  ],
};

export const storyGridOurStory = {
  title: "Vår historia",
  description:
    "Stockiful är byggt av aktienördar, för aktienördar. Vi har själva varit med om det ibland är svårt att minnas alla tiotaggare som man lyckas pricka in. Därför vill vi erbjuda möjligheten att kunna rama in sina fina aktieminnen.",
  slug: "973636",
  size: "50x70cm",
  type: "poster",
  theme: "classic",
  image: "/images/frontpage/tripple-couch.png",
};

export const inspoConfig = {
  title: "Inspiration",
  description:
    "Här hittar du exempel på några utav de mest ägda aktierna på Avanza. Kom ihåg att du kan ändra text och tidsperiod på dem alla, så att du får din poster precis som du vill ha den.",
  items: [
    {
      title: "Embracer",
      description: "Embracer i Noir, 2021",
      image: "/images/inspo/Inspo Embracer.jpg",
      slug: "2598207",
      type: "poster",
      theme: "noir",
      size: "50x70cm",
    },
    {
      title: "Investor",
      description: "Investor i Stockiful Classic",
      image: "/images/inspo/Inspo Investor.jpg",
      slug: "1034561",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
    {
      title: "H&M",
      description: "H&M i Noir, utan undertext",
      image: "/images/inspo/Inspo HM.jpg",
      slug: "2926",
      type: "poster",
      theme: "noir",
      size: "50x70cm",
    },
    {
      title: "SAS",
      description: "SAS i Stockiful Classic",
      image: "/images/inspo/Inspo SAS.jpg",
      slug: "4705",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
    {
      title: "Latour",
      description: "Latour i Noir <3.",
      image: "/images/inspo/Inspo Latour.jpg",
      slug: "3411",
      type: "poster",
      theme: "noir",
      size: "50x70cm",
    },
    {
      title: "SBB Norden B",
      description: "SBB i Stockiful Classic",
      image: "/images/inspo/Inspo SBB.jpg",
      slug: "949975",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
    {
      title: "Sinch",
      description: "Sinch i Stockiful Classic",
      image: "/images/inspo/Inspo Sinch.jpg",
      slug: "1175000",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
    {
      title: "Veteranpoolen",
      description: "Veteranpoolen i Stockiful Classic",
      image: "/images/inspo/Inspo Veteranpoolen.jpg",
      slug: "3539785",
      type: "poster",
      theme: "classic",
      size: "50x70cm",
    },
    {
      title: "Volvo B",
      description: "Klassisk aktie i Stockiful Classic",
      image: "/images/inspo/Inspo Volvo B.jpg",
      slug: "1146",
      type: "classic",
      theme: "noir",
      size: "50x70cm",
    },
    {
      title: "Kinnevik",
      description: "Kinnevik B i Noir",
      image: "/images/inspo/Inspo Kinnevik.jpg",
      slug: "924",
      type: "poster",
      theme: "noir",
      size: "50x70cm",
    },
    {
      title: "Gissa aktien!",
      description: "Kan du gissa vilken aktie detta är?",
      image: "/images/inspo/Inspo Vilken.jpg",
      slug: "4705",
      type: "poster",
      theme: "Classic",
      size: "50x70cm",
    },
    {
      title: "Din egen",
      description: "Sök ibland tusentals aktier",
      image: "/images/inspo/Easy Come.jpg",
      slug: "1146",
      type: "poster",
      theme: "noir",
      size: "50x70cm",
    },
  ],
};
